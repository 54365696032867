import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout>
      <h2>HEALTHY EATING</h2>
      <h3>Why does healthy eating matter?</h3>
      <p>Your general health and your resistance to many diseases depend a lot on eating a healthy, balanced diet. Your eating and drinking habits also affect the health of your teeth. Dental plaque is a soft, sticky substance that builds up on your teeth. It is mostly made up of bacteria, which feed on sugar from food and drink, producing acids as a waste product. The acids attack the teeth by dissolving the minerals in the tooth surface. If this happens too often, tooth decay results. Acids in food and drink can dissolve away tooth surface.</p>
      <p>All fizzy drinks (including "diet" brands and fizzy mineral water), all squashes and all fruit juices are acidic to varying degrees. Pickles and citrus fruits are examples of acidic types of food. After an acid attack, teeth can repair themselves, given the opportunity. It helps if fluoride (a mineral that is an ingredient in most toothpaste, and is in the water supply in some areas) is present in the mouth.</p>
      <p>Most dental problems can be avoided if you:</p>
      <ul>
        <li>remove plaque by brushing twice a day with a fluoride toothpaste</li>
        <li>don't have sugary and I or acidic food and drink too often during the day. Try to have these mostly at meal times not in between</li>
        <li>Chewing sugar-free gum can also help, as this increases the flow of saliva, which helps teeth to repair themselves. Between meals you should only have "safe" drinks, which are not sugary or acidic. Milk and water are "safe" drinks. So are tea and coffee if you do not add sugar to them (you can use non-sugar sweeteners). You should try and avoid snacking between meals. If you do snack, only have "safe" snacks, which are not sugary or acidic. Fruits, vegetables and products (such as sandwiches, toast, crumpets and pitta bread) are all "safe" snacks. For the sake of your general health, you should be eating at least five portions of vegetables and fruit every day. Dairy products, such as cheese, are "safe" snacks for teeth; but, for the sake of your general health, choose lower fat varieties and don't have too much.</li>
      </ul>
      <p>Remember the rules:</p>
      <ul>
        <li>Always brush your teeth twice a day with fluoride toothpaste.</li>
        <li>Only have "safe" drinks and "safe" snacks between meals.</li>
        <li>Eat at least five portions of vegetables and fruit every day.</li>
      </ul>
    </LightboxLayout>
  )
}

export default Page